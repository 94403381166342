export class Header {
    constructor() {
        this.burger()
    }

    burger() {
        const burger = document.querySelector('#burger')

        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
            })

            const nav = document.querySelector('.menu-menu-principal-container');
            nav.addEventListener('click', evt => {
                if (burger.classList.contains('active')) {
                    burger.classList.remove('active')
                }
            })
        }
    }
}
